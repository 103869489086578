import React from "react"
import { graphql, navigate } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { colours, device } from '../style/variables'
import BannerCard from '../components/common/BannerCard'
import BlogHeader from '../components/common/BlogHeader'
import TextBanner from '../components/common/TextBanner'

const Container = styled.div`
  margin: 3rem auto 0 auto;

  @media ${device.tablet} {
    max-width: 800px;
  }

  @media ${device.desktop} {
    margin: 0.5rem auto;
    max-width: 1140px;
    margin-top: 4rem;
  }
`

const Content = styled.div`
  .paragraph {
    color: pink;
  }
  p, li {
    font-size: 15px;
  }
  padding: 16px;
  width: 66%;
  @media ${device.smartphone} {
    width: 100%;
  }
`

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      headerimage {
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid
        }
      }
      subtitle {
        subtitle
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      bottomTitle
      bottomText
      bottomButtonText
      bottomButtonLink
    }
  }
`
const BlogPost = props => {
  return (
    <Layout white>
      <Seo title={props.data.contentfulBlogPost.title} />
      <BlogHeader image={props.data.contentfulBlogPost.headerimage.fluid} text={props.data.contentfulBlogPost.title} />
      <Container>
        {props.data.contentfulBlogPost.subtitle && <TextBanner text={props.data.contentfulBlogPost.subtitle.subtitle} />}
        <div className="content" style={{ marginBottom: 80 }}>
          <span className="meta">
          </span>
          <Content
            dangerouslySetInnerHTML={{
              __html: props.data.contentfulBlogPost.body.childMarkdownRemark.html,
            }}
          >
          </Content>
          {props.data.contentfulBlogPost.bottomTitle && props.data.contentfulBlogPost.bottomText && props.data.contentfulBlogPost.bottomButtonText && props.data.contentfulBlogPost.bottomButtonLink && (
            <BannerCard
              title={props.data.contentfulBlogPost.bottomTitle}
              content={props.data.contentfulBlogPost.bottomText}
              buttonLabel={props.data.contentfulBlogPost.bottomButtonText}
              onClick={() => navigate(props.data.contentfulBlogPost.bottomButtonLink)}
              foregroundColor={colours.lightBlue}
              backgroundColor={colours.allowaBlue}
              fontColor="#001E3F"
              invert
            />
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default BlogPost