import React from 'react'
import styled from 'styled-components'
import PrimaryButton from './Button'
import { colours, device } from '../../style/variables'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  @media ${device.smartphone} {
    overflow-x: hidden;
  }
`
const Background = styled.div<{ color: string; invert?: boolean }>`
  width: 95%;
  height: 171px;

  background: ${props => props.color};
  border-radius: 20px;
  transform: ${props => (props.invert ? ' rotate(-1.13deg)' : 'matrix(1, 0.02, 0.02, -1, 0, 0)')};
  position: absolute;
  left: 22px;
  top: 0px;
  z-index: 0;
  @media ${device.smartphone} {
    flex-direction: column;
    width: 100%;
  }
`

const Foreground = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 45px;
  min-height: 171px;
  padding-top: 40px;
  padding-bottom: 20px;
  border-radius: 20px;
  width: 100%;
  background: ${props => props.color};
  z-index: 1;
  @media ${device.smartphone} {
    flex-direction: column;
    width: 100%;
    border-radius: 0px;
  }
`

const LeftContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 16px;
`

const RightContent = styled.div`
  flex: 1;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

const Title = styled.p<{ color: string }>`
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 11px;
  color: ${props => props.color};
  @media ${device.smartphone} {
    text-align: center;
  }
`

const Text = styled.p<{ color: string }>`
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: ${props => props.color};
  margin-bottom: 0px;
  @media ${device.smartphone} {
    margin-bottom: 40px;
    margin-top: 30px;
    text-align: center;
  }
`

function BannerCard({
  title,
  content,
  buttonLabel,
  onClick,
  foregroundColor = '#ecf0fa',
  backgroundColor = colours.blue,
  fontColor = '#0b315e',
  invert = false,
}): React.ReactElement {
  return (
    <Container>
      <Background color={backgroundColor} invert={invert}></Background>
      <Foreground color={foregroundColor}>
        <LeftContent>
          <Title color={fontColor}>{title}</Title>
          <Text color={fontColor}>{content}</Text>
        </LeftContent>
        <RightContent>
          <PrimaryButton orange label={buttonLabel} onClick={onClick} />
        </RightContent>
      </Foreground>
    </Container>
  )
}

export default BannerCard
