import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'

const Container = styled.div`
  color: ${colours.blue};
  font-size: 15px;
  font-weight: 400;
  margin: 0 auto;

  @media ${device.desktop} {
    display: flex;
    justify-content: center;
    margin: 5rem auto 2rem auto;
  }
`

const InnerWrapper = styled.div`
  background-color: #fff1ef;
  padding: 3rem;

  @media ${device.desktop} {
    border-radius: 20px;
    max-width: 1140px;
    padding: 3rem 5rem;
  }
`

function Block1(props): React.ReactElement {
  return (
    <Container>
      <InnerWrapper>{props.text}</InnerWrapper>
    </Container>
  )
}

export default Block1
