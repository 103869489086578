/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, pathname, image, keywords, index }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            url
            image
          }
        }
      }
    `
  )

  const seo = {
    metaDescription: description || site.siteMetadata.description,
    defaultTitle: site.siteMetadata?.title,
    metaImage: image || site.siteMetadata.image,
    metaKeywords: keywords || site.siteMetadata.keywords,
    canonical: pathname ? `${site.siteMetadata.url}${pathname}` : null,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={seo.defaultTitle ? `%s | ${seo.defaultTitle}` : null}
      link={
        seo.canonical
          ? [
            {
              rel: "canonical",
              href: seo.canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: seo.metaDescription,
        },
        {
          name: `keywords`,
          content: seo.metaKeywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: seo.metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: seo.metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: seo.metaDescription,
        },
        {
          name: `twitter:image`,
          content: seo.metaImage,
        },
        {
          name: `robots`,
          content: index ? `all` : `noindex, nofollow, noimageindex`,
        },
        {
          name: `googlebot`,
          content: index ? `all` : `noindex, nofollow, noimageindex`,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  index: true, // set as `true` to activate indexing site-wide
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  pathname: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.bool,
}

export default Seo