import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'

import bg from '../../assets/images/blog/header-bg.svg'
import Img from 'gatsby-image'

const Container = styled.div`
  background: url(${bg});
  background-color: ${colours.blue};
  color: ${colours.offwhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -5rem auto 0 auto;
  padding-top: 10rem;

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5rem;
  }
`

const InnerWrapper = styled.div`
  margin: 0 auto;

  @media ${device.tablet} {
    margin: 0 auto 0 2rem;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 1140px;
    width: 100%;
  }
`

const LeftBlock = styled.div`
  display: none;
  position: relative;

  @media ${device.desktop} {
    display: flex;
    margin-left: 5rem;
    padding-top: 1rem;
    top: 3rem;
    width: 50%;
  }
`

const WhiteBlock = styled.div`
  background-color: white;
  border-radius: 8px;
  height: 370px;
  left: -1.2rem;
  position: absolute;
  top: 3.4rem;
  transform: rotate(-4.1deg);
  width: 390px;
`

const ImageWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
`

const RightBlock = styled.div`
  @media ${device.desktop} {
    margin-left: 1rem;
    margin-top: 5rem;
    width: 50%;
  }
`

const Text = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 260px;
  text-align: center;

  span {
    font-weight: 700;
  }

  @media ${device.tablet} {
    font-size: 36px;
    line-height: 54px;
    max-width: 400px;
    text-align: left;
  }

  @media ${device.desktop} {
    max-width: 600px;
    min-height: 150px;
  }
`

const Dash = styled.div`
  border: 5px solid ${colours.orange};
  display: block;
  margin: 2rem auto 3rem auto;
  width: 60px;

  @media ${device.tablet} {
    margin: 4rem auto 4rem 0;
  }

  @media ${device.desktop} {
    margin: 3.5rem auto 4rem 0;
  }
`

function Header(props): React.ReactElement {
  return (
    <Container>
      <InnerWrapper>
        <LeftBlock>
          {/* <WhiteBlock /> */}
          <ImageWrapper>
            {props.image && <img src={props.image.src} style={{ borderRadius: 8 }} />}
            {/* // <Img className="featured" fluid={props.image} />} */}
          </ImageWrapper>
        </LeftBlock>
        <RightBlock>
          <Text>{props.text}</Text>
          <Dash />
        </RightBlock>
      </InnerWrapper>
    </Container>
  )
}

export default Header
